<template>
	<Page ref="page">
		<template slot="search">
			<el-form inline :model="params" ref="filtersForm">
				<el-form-item label="序列号" prop="sn">
					<el-select
						v-model="params.sn"
						filterable
						remote
						reserve-keyword
						placeholder="请输入序列号"
						:remote-method="listSn"
						:loading="loading"
					>
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否可见" prop="showOrNot">
					<el-select v-model="params.showOrNot" placeholder="请选择" clearable>
					    <el-option label="可见" value="1" />
						<el-option label="隐藏" value="0" />
					  </el-select>
				</el-form-item>
				<el-form-item label="是否查询增量" prop="increment">
					<el-checkbox v-model="params.increment" :true-label="1" :false-label="0" />
				</el-form-item>
			</el-form>
		</template>

		<el-table
			:data="tableData"
			style="margin-bottom: 40px; width: 100%"
			row-key="code"
			border
			v-loading="loading"
		>
			<el-table-column width="50" />
			<el-table-column
				align="center"
				label="序号"
				type="index"
				width="60"
			/>
			<el-table-column
				show-overflow-tooltip
				prop="code"
				label="子件料号"
				width="230"
			/>
			<el-table-column
				show-overflow-tooltip
				prop="name"
				label="子件名称"
			/>
			<el-table-column
				show-overflow-tooltip
				prop="spec"
				label="子件规格"
			/>
			<el-table-column
				header-align="center"
				align="center"
				show-overflow-tooltip
				label="是否可见"
				width="80"
			>
				<template slot-scope="{ row }">
					{{ showFilter(row.showOrNot) }}
				</template>
			</el-table-column>
			<el-table-column
				header-align="center"
				align="center"
				show-overflow-tooltip
				label="状态"
				width="80"
			>
				<template slot-scope="{ row }">
					{{ statusFilter(row.status) }}
				</template>
			</el-table-column>
			<el-table-column
				header-align="center"
				align="center"
				show-overflow-tooltip
				label="创建时间"
				width="160"
				prop="makeDate"
			/>
		</el-table>
	</Page>
</template>

<script>
export default {
	data() {
		return {
			loading: false,
			options: [],
			tableData: [],
			params: {
				sn: '',
				pageNum: 1,
				pageSize: 20
			}
		}
	},
	methods: {
		statusFilter(value) {
			const obj = { 1: '正常', 0: '现已禁用' }
			return obj[value] || value || '-'
		},
		showFilter(value) {
			const obj = { 1: '可见', 0: '隐藏' }
			return obj[value] || value || '-'
		},
		async listSn(keyword) {
			try {
				if (keyword.length > 6) {
					this.loading = true
					this.params.sn = keyword
					const options = await this.$api.bom.pageSnByKey(this.params)
					this.options = options.map(option => {
						return {
							value: option,
							label: option
						}
					})
					console.log(this.options)
				} else {
					this.options = []
				}
			} finally {
				this.loading = false
			}
		},
		async getData() {
			try {
				this.loading = true
				const sn = this.params.sn
				if (sn.length == 0) {
					this.$message.error('请先选择序列号')
				} else {
					this.tableData = await this.$api.bom.listAssetBom(
						this.params
					)
				}
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		}
	}
}
</script>
